import * as React from "react"
import Seo from "../components/seo"
import "../assets/css/style.css"
import "../assets/css/layout.css"
import "../assets/css/blog-style.css"
import { graphql } from "gatsby"
import Blog from "../components/blog/blog"
import { languages } from "../const/languages"

const BlogPage = ({ data }) => <Blog data={data} lang={languages.PL}/>

export const Head = () => {
  return (
    <>
      <Seo
        title="AR-Labs.io | Przeczytaj więcej o AR i VTO dla Twojego biznesu"
        description="Jeśli chcesz wiedzieć co to znaczy i jak wprowadzić rzeczywistość rozszerzoną lub wirtualną przymierzalnię w swoim biznesie, ta strona jest dla Ciebie. Wiadomości, raporty i plotki o AR!"         
        canonicalURL="https://staging-area.ar-labs.io/pl/blog"
      />
    </>
  )
}

export const BlogContentQuery = graphql`
  query BlogPageQuery {
    allWpPost(filter: {tags: {nodes: {elemMatch: {slug: {eq: "language-pl"}}}}}) {
      nodes {
        id
        title
        date
        categories {
          nodes {
            name
          }
        }
        excerpt
        featuredImage {
          node {
            publicUrl
          }
        }
        content
        author {
          node {
            name
            avatar {
              height
              width
              url
            }
          }
        }
      }
    }
  }
`

export default BlogPage
